import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import { css, StyleSheet } from "aphrodite";
import React, { FC } from "react";
import TranslateButtonIcon from "@components/ui/translations/TranslateButtonIcon";
import { motion, AnimatePresence } from "framer-motion";

type Props = {
  translationModeEnabled: boolean;
  setTranslationModeEnabled: (val: boolean) => void;
  tooltipText?: string;
  buttonHeight?: "36px" | "40px";
  buttonBorderRadius?: "8px" | "40px";
};

const TranslationModeToggle: FC<Props> = ({
  translationModeEnabled,
  setTranslationModeEnabled,
  tooltipText = "Enable Translation Mode",
  buttonHeight,
  buttonBorderRadius,
}) => {
  return (
    <div className={css(styles.container)}>
      <AnimatePresence initial={false}>
        <motion.div
          animate={
            translationModeEnabled
              ? "translationModeEnabled"
              : "translationModeDisabled"
          }
          className={css(styles.label)}
          variants={{
            translationModeEnabled: {
              width: "unset",
              opacity: 1,
              marginRight: 10,
              transition: {
                delay: 0,
                duration: 0.3,
              },
            },
            translationModeDisabled: {
              width: 0,
              opacity: 0,
              marginRight: 0,
              transition: {
                delay: 0,
                duration: 0.3,
              },
            },
          }}
        >
          Translation Mode
        </motion.div>
      </AnimatePresence>
      <TranslateButtonIcon
        active={translationModeEnabled}
        onClick={() => setTranslationModeEnabled(!translationModeEnabled)}
        tooltipText={tooltipText}
        height={buttonHeight}
        borderRadius={buttonBorderRadius}
      />
    </div>
  );
};

export default TranslationModeToggle;

const styles = StyleSheet.create({
  container: {
    display: "flex",
    alignItems: "center",
    fontSize: 13,
    color: deprecatedTones.gray11,
    whiteSpace: "nowrap",
  },
  label: {
    overflow: "hidden",
  },
});
